import { getDate, getDay, getMonth, getYear, isAfter } from 'date-fns';
import i18nextInit from 'dd-client/site/i18n/i18nextInit';

const { t } = i18nextInit;
const text = {
  deliveryMonday: t('Order now and get the delivery next Monday'),
  deliveryTuesday: t('Order will be delivered next Tuesday'),
  orderAfter7pm: t('Order now and get the delivery the day after tomorrow'),
  orderBy7pm: t('Order by 7pm, get delivery tomorrow'),
};

const getDeliveryInformation = (deliveryInformation: null | string, currentDateTime: Date): string => {
  if (deliveryInformation) {
    return deliveryInformation;
  }

  const currentYear = getYear(currentDateTime);
  const currentMonth = getMonth(currentDateTime);
  const currentMonthDay = getDate(currentDateTime);
  const currentWeekDay = getDay(currentDateTime);
  const currentDayAt19 = new Date(currentYear, currentMonth, currentMonthDay, 19);

  if (
    currentWeekDay >= 1
    && currentWeekDay <= 4
    && !isAfter(currentDateTime, currentDayAt19)
  ) {
    return text.orderBy7pm;
  }

  if (
    currentWeekDay >= 1
    && currentWeekDay <= 3
    && isAfter(currentDateTime, currentDayAt19)
  ) {
    return text.orderAfter7pm;
  }

  if (
    (currentWeekDay === 4 && isAfter(currentDateTime, currentDayAt19))
    || (currentWeekDay === 5 && !isAfter(currentDateTime, currentDayAt19)
    )
  ) {
    return text.deliveryMonday;
  }

  return text.deliveryTuesday;
};

export {
  getDeliveryInformation,
  text,
};
